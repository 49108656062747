body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  margin: 0;
}

#main {
  padding-top: 80px;
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#main-content {
  float: left;
  width: 100%;
}

.form-signin {
  max-width: 300px;
  padding: 19px 29px 29px;
  margin: 0 auto 20px;
  margin-top: 90px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0.5%);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgb(0 0 0.5%);
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

[class^='icon-'] {
  margin-right: 2px;
  position: relative;
  top: -2px;
}

.form-signin input[type='text'],
.form-signin input[type='password'] {
  height: auto;
  margin-bottom: 15px;
}

.form-control {
  display: block;
  width: 100%;
  min-width: 125px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0.8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0.8%);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

option {
  font-size: 16px;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.bg-light {
  padding-left: 10px;
  background-color: #f8f9fa !important;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  height: 50px;
}

.navbar__block {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
}

.navbar__title {
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
}

.navbar__btn {
  position: absolute;
  right: 0;
  margin: 0;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.navbar__btn:hover {
  background-color: #f8f9fa;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline {
  padding-top: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-group label {
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

.form-control-2 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.btn-2 {
  margin-left: 10px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.table-bordered {
  border: 1px solid #e9ecef;
}

table {
  width: 100%;
  font-size: 14px;
  margin-top: 30px;
  border-collapse: collapse;
}

thead {
  text-align: center;
}

.container {
  padding: 15px 15px 0 15px;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn-default {
  height: 38px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:hover {
  background-color: #f8f9fa;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.box {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  -khtml-border-radius: 3px;
  border: 1px solid #d5d5d5;
  box-shadow: 0 1px 0 rgb(0 0 11%);
  margin-bottom: 3%;
  background: #fff;
}

.box .header {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  position: relative;
  box-shadow: inset 0 1px 0 #fff, 0 1px 2px rgb(0 0 2%);
}

.box .header h4 {
  position: relative;
  padding: 9px 0;
  padding-top: 8px;
  padding-left: 10px;
  border-bottom: 1px solid #c2c2c2;
  margin: 0;
  font-size: 15px;
  color: #444;
  line-height: 20px;
  font-weight: bold;
}

.box .content {
  padding: 0;
  margin: 0;
}

.content.pad {
  padding: 20px;
}

form {
  margin-bottom: 0;
}

.pad label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.select-checkbox {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.select-box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 5px 8px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.open .select-box {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.selected-items {
  flex-grow: 1;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  margin-left: 5px;
}

.open .arrow {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  margin-top: 5px;
  width: 100%;
}

.options li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.options li label {
  display: block;
  margin: 0;
  font-weight: 100;
}
